import React, { useReducer, createContext, Dispatch, PropsWithChildren } from "react";
import { useAsync } from "react-async-hook";
import { AuthService, authResponseCodeEnum } from "../services/auth";
import isEqual from "lodash/isEqual";
import { Logger } from "../utils/logging";
import { IAMAccessPolicy } from "lib/utils/passwords/accountPolicy";

function noOp() { }

type AuthenticationContextState = {
  /** User is currently authenticated */
  isAuthenticated: boolean;
  /** User who is currently logged in */
  currentUser: User | null;
  /** Currently loading data bout the logged in user */
  loadingUser: boolean;
  /** Auth challenge response for the current stage in the login flow */
  challengeResult: AuthChallengeResult | null;
};

const initialState: AuthenticationContextState = {
  isAuthenticated: false,
  currentUser: null,
  loadingUser: true,
  challengeResult: null,
};

const AuthenticationContext = createContext<{
  state: AuthenticationContextState;
  dispatch: Dispatch<DispatchType>;
}>({ state: initialState, dispatch: noOp });

export function AuthenticationContextProvider(props: PropsWithChildren<unknown>) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useAsync(async () => {
    dispatch({ type: ReducerActions.SetLoadingUser, payload: true });

    try {
      const getAuthenticatedUser = async () => {
        const currentUser = await AuthService.getCurrentUser();
        if (!currentUser) {
          Logger.error("Authentication error: invalid user");
          AuthService.logout();
          dispatch({ type: ReducerActions.Logout });
        } else {
          dispatch({ type: ReducerActions.SetAuthenticatedUser, payload: currentUser as User });
        }
      };
      // make sure we aren't already logged in
      const IsAuthenticated = !AuthService.tokenExpired();
      dispatch({ type: ReducerActions.SetIsAuthenticated, payload: IsAuthenticated });
      IsAuthenticated && (await getAuthenticatedUser());
    } catch (x) {
      AuthService.logout();
      dispatch({ type: ReducerActions.Logout });
    } finally {
      dispatch({ type: ReducerActions.SetLoadingUser, payload: false });
    }
  }, [dispatch]);

  return (
    <AuthenticationContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AuthenticationContext.Provider>
  );
}

export function useAuthentication() {
  const { state, dispatch } = React.useContext(AuthenticationContext);
  const { currentUser, loadingUser, isAuthenticated, challengeResult } = state;

  const login = (user: User) => {
    dispatch({ type: ReducerActions.Login, payload: user });
  };

  const logout = () => {
    dispatch({ type: ReducerActions.Logout });
  };

  const updateCurrentUserData = (user: User) => {
    dispatch({ type: ReducerActions.UpdateCurrentUserData, payload: user });
  };

  function setAuthChallengeResult(challenge: AuthChallengeResult | null) {
    dispatch({ type: ReducerActions.SetAuthChallengeResult, payload: challenge });
  }

  return {
    login,
    logout,
    currentUser,
    loadingUser,
    isAuthenticated,
    challengeResult,
    updateCurrentUserData,
    setAuthChallengeResult,
  };
}

export default AuthenticationContext;

enum ReducerActions {
  Login,
  Logout,
  SetAuthChallengeResult,
  SetIsAuthenticated,
  SetAuthenticatedUser,
  SetLoadingUser,
  UpdateCurrentUserData,
}

type ReducerActionWithUser = ReducerActions.Login | ReducerActions.SetAuthenticatedUser;
type ReducerActionWithBoolean = ReducerActions.SetIsAuthenticated | ReducerActions.SetLoadingUser;

type User = {
  isExternal: boolean;
  email?: string;
  accountPolicy?: { policy?: IAMAccessPolicy };
};

export type AuthChallengeResult = {
  /** Code indicating state of challenge flow */
  code: keyof typeof authResponseCodeEnum;
  /** Value returned by the challenge request */
  challenge?: Record<string, string>;
  parameters?: {
    required?: string[];
    code?: {
      type: string;
      destination: string;
    };
    policy?: IAMAccessPolicy;
  };
};

type DispatchType<T extends ReducerActions = ReducerActions> = T extends ReducerActions.Logout
  ? { type: ReducerActions.Logout }
  : T extends ReducerActionWithBoolean
  ? { type: ReducerActionWithBoolean; payload: boolean }
  : T extends ReducerActionWithUser
  ? { type: ReducerActionWithUser; payload: User | null }
  : T extends ReducerActions.UpdateCurrentUserData
  ? { type: ReducerActions.UpdateCurrentUserData; payload: User }
  : T extends ReducerActions.SetAuthChallengeResult
  ? { type: ReducerActions.SetAuthChallengeResult; payload: AuthChallengeResult | null }
  : never;

function reducer(
  state: AuthenticationContextState,
  action: DispatchType
): AuthenticationContextState {
  switch (action.type) {
    case ReducerActions.Login:
      return {
        ...state,
        isAuthenticated: true,
        currentUser: action.payload,
      };
    case ReducerActions.Logout:
      return {
        ...state,
        isAuthenticated: false,
        currentUser: null,
      };
    case ReducerActions.SetAuthChallengeResult:
      return {
        ...state,
        challengeResult: action.payload,
      };
    case ReducerActions.SetIsAuthenticated:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case ReducerActions.SetAuthenticatedUser:
      return isEqual(action.payload, state.currentUser)
        ? state
        : {
            ...state,
            isAuthenticated: true,
            currentUser: action.payload,
          };
    case ReducerActions.SetLoadingUser:
      return {
        ...state,
        loadingUser: action.payload,
      };
    case ReducerActions.UpdateCurrentUserData:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
